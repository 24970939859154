export const tableConfig = [{
    prop: "name",
    label: "套餐名称",
    fixed: true
},
{
    prop: "note",
    label: "套餐描述",
    fixed: true
},
{
    prop: "amount",
    label: "实付价格",
},
{
    prop: "giveAmount",
    label: "赠送苗币数量",
},
{
    prop: "createTime",
    label: "创建时间",
}
]

export const formConfig= [{
    prop: "name",
    label: "套餐名称",
    fixed: true
},
{
    prop: "note",
    label: "套餐描述",
    fixed: true
},
{
    prop: "amount",
    label: "实付价格",
},
{
    prop: "giveAmount",
    label: "赠送苗币数量",
}]
