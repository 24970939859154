import {baseUrl} from "@/main.js"
import {getStorage} from "@/utils/tools"
import { operateInProcess } from "@/components/operateInProcess/operateInProcess"
import axios from "axios"
import showToast from "./showToast"

export default function(url,params,setting={}){
    let option = {
        method:'POST',
        fileName:'报表',
        ...setting
    }
   
    let tokenValue='',tokenName = '',headers={
        IS_EXPORT:true,
        'Content-Type': 'application/json;charset=UTF-8',
    }

    if(typeof getStorage('tokenObj') == 'string'){
        const res = JSON.parse(getStorage('tokenObj'))
        tokenValue = res.tokenValue
        tokenName = res.tokenName
        headers[tokenName] = tokenValue
    }
    if(option.columnNames && option.columnNames.length){
        headers['FIELD_NAME'] = option.columnNames
    }

    operateInProcess({title:'正在导出···',show:true})
    axios({
        data:params,
        method: option.method,
        url: baseUrl+url,
        headers,
        responseType: 'blob',
        dataType: 'json'
    }).then(res => {
        operateInProcess({show:false})
        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf-8' });
        // const fileName = option.fileName+'.xls';
        const elink = document.createElement('a');
        if ('download' in elink) {
            // 非IE下载
            // elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
        } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
        }
    }).catch(err=>{
        operateInProcess({show:false})
        showToast.error('导出失败')
    });
}