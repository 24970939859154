export default function(
    store,route,router,
    formColumnConfig,//表单label相关配置
    formOption,//表单配置
    clickBtnSelectObj,//自定义点击类型按钮配置
    fromHndleEventObj,
    _axios,
    showToast,
    showConfirm
){
    formOption.labelWidth = 140
    formOption.newApiOption.api = `/sys/shoppingPoolSetting/addOrModify`

    getAreaList()
    async function getAreaList(){
        const res = await _axios('post','/sys/productRegion/list',{},true)
        formColumnConfig[3].option = res
        formColumnConfig[3].lkey = 'regionName'
        formColumnConfig[3].vkey = 'id'
    }
}
