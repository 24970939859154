export const tableConfig=[
    {
        prop: "username",
        label: "用户名",
    },
    {
        prop: "operation",
        label: "用户操作",
    },
    {
        prop: "time",
        label: "执行时长(毫秒)",
    },
    {
        prop: "type",
        label: "日志类型",
    },
    {
        prop: "createDate",
        label: "创建时间"
    },
    {
        prop: "beginTime",
        label: "开始日期"
    },
    {
        prop: "endTime",
        label: "结束日期"
    }
]

export const formConfig=[
    {
        prop: "username",
        label: "用户名",
        disabled:true
    },
    {
        prop: "operation",
        label: "用户操作",
        disabled:true
    },
    {
        prop: "ip",
        label: "IP地址",
        disabled:true
    },
    {
        prop: "time",
        label: "执行时长(毫秒)",
        disabled:true
    },
    {
        prop: "type",
        label: "日志类型",
        disabled:true
    },
    {
        prop: "createDate",
        label: "创建时间",
        disabled:true
    },
    {
        prop: "beginTime",
        label: "开始日期",
        disabled:true
    },
    {
        prop: "endTime",
        label: "结束日期",
        disabled:true
    },
    {
        prop: "method",
        label: "请求方法",
        type:'textarea',
        disabled:true
    },
    {
        prop: "params",
        label: "请求参数",
        type:'textarea',
        // disabled:true
    },
]
