import store from "@/store";
export default function(dictType){
    let option = []
    if(dictType){
        const list = store.state.page.dictValList || []
        const res = list.find(v=>v.dictType===dictType)
        if(res && res.children){
            const {children} = res
            option = children
        }
    }
    return {
        option
    }
}