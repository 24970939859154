export const tableConfig = [
    {
        prop:'picUrl',
        label:'图片',
        type: 'img'
    }, {
        prop: "status",
        type: 'state',
        label: "状态",
        dictType:'status'
    },
    {
        prop: "createTime",
        label: "创建时间"
    },
    {
        prop: "type",
        label: "广告类型",
        type:'state',
        dictType:'ad_type'
    }
]
export const formConfig = [
  {
        prop:'picUrl',
        label:'图片',
        type:'img'
    }, {
        prop: "status",
        label: "状态",
        type: 'select',
        dictType:'status'
    },
    {
        prop: "type",
        label: "广告类型",
        type:'select',
        dictType:'ad_type'
  
    }
]