export const tableConfig=[
    {
        prop: "name",
        label: "角色名",
    },
    {
        prop: "code",
        label: "角色代码",
    },
    {
        prop: "status",
        label: "状态",
        type:"state",
        dictType:'enable-status',
    },
    {
        prop: "createTime",
        label: "创建时间"
    }
]

export const formConfig=[
    {
        prop: "name",
        label: "角色名"
    },
    {
        prop: "code",
        label: "角色代码",
    },
    {
        prop: "status",
        label: "状态",
        type:"select",
        dictType:'enable-status',
    }
]