export default function(
    store,route,router,
    formColumnConfig,//表单label相关配置
    formOption,//表单配置
    clickBtnSelectObj,//自定义点击类型按钮配置
    fromHndleEventObj,
    _axios,
    showToast,
    showConfirm
){
    formOption.labelWidth = 120
    formOption.newApiOption.api = `/sys/giftPacksRewardLevelSetting/addOrModify`

    getUserLevel()
    async function getUserLevel(){
        const res = await _axios('post','/sys/userLevel/list',{type:3},true)
        formColumnConfig[0].option = res
        formColumnConfig[0].lkey = 'levelName'
        formColumnConfig[0].vkey = 'levelId'
        formColumnConfig[1].option = res
        formColumnConfig[1].lkey = 'levelName'
        formColumnConfig[1].vkey = 'levelId'
    }
}
