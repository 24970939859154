export const tableConfig = [
    {
        prop: "name",
        label: "微信昵称"
    },
    {
        prop: "phone",
        label: "手机号",
    },
    {
        prop: "planStore",
        label: "店铺名称"
    },
    {
        prop: "status",
        label: "是否已赠送",
        type:"state",
        option:[
            {label:'已赠送',value:1,style:'color:#3f48cc;font-weight:bold'},
            {label:'未处理',value:0,style:'color:#a349a4;font-weight:bold'},
        ]
    },
    {
        prop: "note",
        label: "内容",
    },
    {
        prop: "pic",
        label: "图片",
        type: 'mimg',
    },
    {
        prop: "createTime",
        label: "创建时间"
    }
]
export const formConfig = [
    {
        prop: "name",
        label: "微信昵称"
    },
    {
        prop: "phone",
        label: "手机号",
    },
    {
        prop: "planStore",
        label: "店铺名称"
    },
    {
        prop: "note",
        label: "内容",
        type: 'textarea',

    },
    {
        prop: "pic",
        label: "图片",
        type: 'mimg',
    }

]
