export const tableConfig=[
    {
        prop: "version",
        label: "版本号",
    },
    {
        prop: "upgradePoint",
        label: "升级提示",
    },
    {
        prop: "appType",
        label: "app类型",
        type:"state",
        option:[
            {label:"Android",value:1},
            {label:"ios",value:2}
        ]
    },
    {
        prop: "isForce",
        label: "是否强制更新",
        type:"state",
        option:[
            {label:"强制更新",value:1},
            {label:"不强制更新",value:2}
        ]
    },
    {
        prop: "appUrl",
        label: "app下载地址"
    },
    {
        prop: "createTime",
        label: "创建时间"
    }
]

export const formConfig=[
    {
        prop: "version",
        label: "版本号",
    },
    {
        prop: "upgradePoint",
        label: "升级提示",
    },
    {
        prop: "appType",
        label: "app类型",
        type:"select",
        option:[
            {label:"Android",value:1},
            {label:"ios",value:2}
        ]
    },
    {
        prop: "isForce",
        label: "是否强制更新",
        type:"select",
        option:[
            {label:"强制更新",value:1},
            {label:"不强制更新",value:2}
        ]
    },
    {
        prop: "appUrl",
        label: "app下载地址"
    },
]