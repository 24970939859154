export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
){
    
    tableOption.topBtn = [
        {name:'新建',uri:"newData",icon:'{"name":"","type":"primary","plain":false}'}
    ]
    tableOption.rightBtn = [
        {name:'编辑',uri:"editData",icon:'{"name":"","type":"primary","plain":true}'},
        {name:'删除',uri:"delData",icon:'{"name":"","type":"danger","plain":false}'},
    ]
    //搜索
	searchOptionsFuzzyQuery.val = {
	    keys:'dictName',
	    placeholder:'字典名称'
	}

    pageParams.pageSize=15

    formDialogOption.defaultItem = {status:1,isDefault:0,dictType:route.query.dictType}
    
    tableRequestOption.params = {dictType:route.query.dictType}
    tableRequestOption.url = '/sys/sysDict/page'

    //删除参数
    tableDelDataOption.url = '/sys/sysDict/delete'
    tableDelDataOption.type = 'bodyArr'
    tableDelDataOption.valkey = 'dictCode'

    //删除字典后刷新本地缓存
    handleEventObj.delSuccess = ()=>{
        store.dispatch('page/getDictVal')
    }
}