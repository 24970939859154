export const tableConfig = [
    {
        prop: "type",
        label: "认证类型",
        type:'state',
        dictType:'auth_type'
    },
    {
        prop: "status",
        label: "认证状态",
        type:'state',
        dictType:'auth_status'
    }, {
        prop: "pic",
        label: "认证信息1",
        type:'img'
    },
    {
        prop: "pic2",
        label: "认证信息2",
        type:'img'
    },
    {
        prop:'createTime',
        label:'创建时间'
    }
    ,
    {
        prop:'updateTime',
        label:'修改时间'
    }
 
]