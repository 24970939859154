import emitter from '@/plugins/mitt'
import { getToken } from '@/utils/tools.js'
import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/index',
    },
    {
        path: '/',
        name: 'home',
        meta: {
            title: '后台管理系统',
            icon: 'education',
            roles: ['admin', 'editor']
        },
        component: () => import('@v/home.vue'),
        children: [
            {
                path: '/index',
                name: 'index',
                meta: {
                    title: '主页',
                    icon: 'education',
                    type: 'custom',
                    formType: 'custom'
                },
                component: () => import('@v/index.vue')
            },
            {
                path: '/system/:id',
                name: 'system',
                meta: {
                    title: '系统设置',
                    type: 'default'
                },
                component: () => import('@c/generalList.vue')
            },
            {
                path: '/finance/:id',
                name: 'finance',
                meta: {
                    title: '财务管理',
                    type: 'default',
                    // formType:'custom'
                },
                component: () => import('@c/generalList.vue')
            },
            {
                path: '/menu',
                name: 'menu',
                meta: {
                    title: '菜单',
                    type: 'custom',
                    formType: 'custom'
                },
                component: () => import('@v/system/menu3.vue')
            },{
                path:'/breed',
                name:'breed',
                meta:{
                    title:'采集品种管理',
                    type:'default '
                },
                component: () => import('@c/generalList.vue')
            },{
                path:'/moments/:id',
                name:'moments',
                meta:{
                    title:'苗木圈',
                    type:'default '
                },
                component: () => import('@c/generalList.vue')
            }
            ,{
                path:'/user/:id',
                name:'user',
                meta:{
                    title:'会员管理',
                    type:'default '
                },
                component: () => import('@c/generalList.vue')
            },{
                path:'/hall/:id',
                name:'hall',
                meta:{
                    title:'供求大厅',
                    type:'default '
                },
                component: () => import('@c/generalList.vue')
            },{
                path:'/handle/:id',
                name:'handle',
                meta:{
                    title:'苗木处理大厅',
                    type:'default '
                },
                component: () => import('@c/generalList.vue')
            },{
                path:'/advertisement/:id',
                name:'advertisement',
                meta:{
                    title:'广告中心',
                    type:'default '
                },
                component: () => import('@c/generalList.vue')
            },{
                path:'/course/:id',
                name:'course',
                meta:{
                    title:'教程管理',
                    type:'default '
                },
                component: () => import('@c/generalList.vue')
            },{
                path:'/crowd/:id',
                name:'crowd',
                meta:{
                    title:'群管理',
                    type:'default '
                },
                component: () => import('@c/generalList.vue')
            },{
                path:'/sysNews/:id',
                name:'sysNew',
                meta:{
                    title:'系统消息',
                    type:'default '
                },
                component: () => import('@c/generalList.vue')
            }

            // {
            // 	path: '/third/:id',
            // 	name: 'third',
            // 	meta: {
            // 	    title: '测试三级菜单',
            // 	    type:'default',
            // 		// formType:'custom'
            // 	},
            // 	component: () => import('@c/generalList.vue')
            // },
            // {
            // 	path: '/exportGroupSelectUser',
            // 	name: 'exportGroupSelectUser',
            // 	meta: {
            // 	    title: '导出团队订单',
            // 	    type:'default',
            // 		// formType:'custom'
            // 	},
            // 	component: () => import('@c/customList/exportGroupSelectUser.vue')
            // },

        ]
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: '登陆'
        },
        component: () => import('@v/login.vue')
    },
    {
        path: '/404',
        name: '404',
        meta: {
            title: '404',
        },
        component: () => import('@v/404.vue')
    },
    {
        path: '/upfiles/mimg',
        name: 'upfiles',
        meta: {
            title: '多图片上传',
            type: 'default',
            // formType:'custom'
        },
        component: () => import('@v/upfilesImg.vue')
    },
]



const router = createRouter({
    history: createWebHashHistory(),
    routes
})

//全局路由守卫
router.beforeEach((to, from, next) => {
    const token = getToken('token') || false
    if (to.meta.title) {
        document.title = to.meta.title
    }
    //如果没登录,都导向登录页
    if (!token) {
        emitter.off('subMenuChange')
        emitter.off('menuClose')
        emitter.off('topBarChange')
        emitter.off('refreshTableList')
        emitter.off('changeSkin')
        if (to.path !== '/login') {
            next({ path: '/login' })
        }
        else {
            next();
        }
    }
    else {
        next();
    }
})

export default router
