export const tableConfig = [
    {
        prop: 'tille',
        label: '标题',

    },
    {
        prop: "url",
        label: "视频",
        type: 'video',
    },
    {
        prop: "createTime",
        label: "创建时间"
    }
]
export const formConfig = [
    {
        prop: 'tille',
        label: '标题',

    },
    {
        prop: "url",
        label: "视频",
        type: 'video',
    }

]
