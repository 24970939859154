export const tableConfig = [{
    prop: "username",
    label: "用户名称",
    fixed: true
},
{
    prop: "amout",
    label: "支付金额",
    fixed: true
},
{
    prop: "payStatus",
    label: "支付状态",
    dictType: 'pay_status',
    type: 'state'
},
{
    prop: "type",
    label: "消费类型",
    type: 'state',
    dictType: 'pay_type'
},
{
    prop: "createTime",
    label: "创建时间",
}
]

