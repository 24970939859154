export const tableConfig=[
    {
        prop: "phone",
        label: "联系方式",
    },
    {
        prop: "type",
        label: "类型",
        type:"state",
        option:[
            {label:'联系电话',value:1},
            {label:'客服',value:2},
        ]
    }
]

export const formConfig=[
    {
        prop: "phone",
        label: "联系方式",
        required:false
    },
    {
        prop: "type",
        label: "类型",
        type:"select",
        option:[
            {label:'联系电话',value:1},
            {label:'客服',value:2},
        ]
    }
]