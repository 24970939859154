export const tableConfig = [
    {
        prop: "createTime",
        label: "发布时间"
    },
    {
        prop: "note",
        label: "发布内容"
    },
    {
        prop: "phone",
        label: "手机号",
    }, {
        prop: "pic",
        label: "图片",
        type: 'mimg'
    }, {
        prop: "url",
        label: "视频",
        type: 'video'
    }, {
        prop: "likeNum",
        label: "点赞数"
    }, {
        prop: "commentNum",
        label: "评论数"
    }
]

export const formConfig = [
    {
        prop: "createTime",
        label: "发布时间"
    },
    {
        prop: "note",
        label: "发布内容"
    },
    {
        prop: "phone",
        label: "手机号",
    }, {
        prop: "pic",
        label: "图片",
        type: 'mimg'
    }, {
        prop: "url",
        label: "视频",
        type: 'video'
    }, {
        prop: "likeNum",
        label: "点赞数"
    }, {
        prop: "commentNum",
        label: "评论数"
    }
]