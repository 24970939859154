export const tableConfig=[
    {
        prop: "title",
        label: "名称",
    },
    {
        prop: "type",
        label: "类型",
        type:"state",
        option:[
            {label:"用户隐私协议",value:1},
            {label:"银行卡支付协议",value:2}
        ]
    }
]

export const formConfig=[
    {
        prop: "title",
        label: "名称",
    },
    {
        prop: "type",
        label: "类型",
        type:"select",
        option:[
            {label:"用户隐私协议",value:1},
            {label:"银行卡支付协议",value:2}
        ]
    },
    {
        prop: "content",
        label: "协议内容",
        type:"editor",
    }
]