export const tableConfig =
    [{
        prop: "title",
        label: "标题",
    },
        {
            prop: "refer",
            label: "简介",
        },
        {
            prop: "createTime",
            label: "创建时间",
        }
    ]

export const formConfig = [{
    prop: "title",
    label: "标题",
},
    {
        prop: "refer",
        label: "简介",
    },
    {
        prop: "note",
        label: "内容",
        type: "editor"
    }

]
