export const tableConfig = [{
    prop: "varieties",
    label: "品种",
    fixed: true
}, {
    prop: "phone",
    label: '手机号'
},
{
    prop: "note",
    label: "备注",

},
{
    prop: "username",
    label: "用户名",
    fixed: true
},
{
    prop: "specificationsLen",
    label: "长度",

},
{
    prop: "address",
    label: "地址",
}, {
    prop: "createTime",
    label: '创建时间'
},
{
    prop: "views",
    label: "浏览量",
},
{
    prop: "phoneNum",
    label: "通话次数",
},
{
    prop: "number",
    label: "数量",
},
{
    prop: "readNumber",
    label: "店铺名称",
}
]

export const formConfig = [{
    prop: "varieties",
    label: "品种",
    required: false
},
{
    prop: "username",
    label: "用户名",
    required: false
},
{
    prop: "specificationsLen",
    label: "长度",
    required: false

},
{
    prop: "note",
    label: "备注",
    required: false

},
{
    prop: "address",
    label: "地址",
    required: false
},
{
    prop: "views",
    label: "浏览量",
    required: false
},
{
    prop: "phoneNum",
    label: "通话次数",
    required: false
},
{
    prop: "number",
    label: "数量",
    required: false
},
{
    prop: "readNumber",
    label: "店铺名称",
    required: false
}]
