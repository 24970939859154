export const tableConfig = [{
    prop: "name",
    label: "品种",
    fixed: true
}, {
    prop: "note",
    label: '推荐理由'
},
{
    prop: "phone",
    label: "手机号",

},
{
    prop: 'price',
    label: "价格"
},
{
    prop: "picUrl",
    label: "图片",
    type: 'mimg'
}, {
    prop: 'adress',
    label: "地址"
}, {
    prop: 'createTime',
    label: '发布时间'
}
]

export const formConfig = [{
    prop: "name",
    label: "品种",
    fixed: true
}, {
    prop: "note",
    label: '推荐理由'
},
{
    prop: "phone",
    label: "手机号",

},
{
    prop: 'price',
    label: "价格"
},
{
    prop: "picUrl",
    label: "图片",
    type: 'mimg'
}]
