export const tableConfig = [
    {
        prop: 'name',
        label: '地区',

    },
    {
        prop: "url",
        label: "图片地址",
        type: 'img',
    },
    {
        prop: "createTime",
        label: "创建时间"
    },
    {
        prop: "updateTime",
        label: "创建时间"
    }
]
export const formConfig = [
    {
        prop: 'name',
        label: '地区',

    },
    {
        prop: "url",
        label: "视频",
        type: 'img',
    }
]
