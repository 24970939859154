<template>
    <Teleport :to="tpl">
        <div class="del-box" v-if="show">
            <div>
                <el-icon color="red"><QuestionFilled /></el-icon>
                <span>{{ title }}</span>
            </div>
            <div>
                <el-button @click.stop="show=false" size="small" style="margin-right:30px">取消</el-button>
                <el-button @click.stop="confirm" size="small" type="danger">确定</el-button>
            </div>
        </div>
    </Teleport>
    <div @click.stop="showConfirm" :style="`display:inline-block;${styles}`">
        <slot></slot>
    </div>
</template>
<script>
    export default{
        name:"popconfirm"
    }
</script>
<script setup>
    import { ref } from 'vue'
    const props = defineProps({
        title:{
            type:String,
            default:'确定删除吗？'
        },
        tpl:{
            type:String,
            default:'body'
        },
        styles:String,
        zIndex:{
            type:Number,
            default:9999
        }
    })

    const emits = defineEmits(['confirm'])

    const show = ref(false)

    const showConfirm = ()=>{
        show.value=true
    }
    const cancel = ()=>{

    }
    const confirm = ()=>{
        emits('confirm')
        setTimeout(()=>{
            show.value=false
        },1000)
    }
</script>
<style lang="scss" scoped>
 .del-box{
        box-shadow: 0 0 3px 3px #ecf5ff;
        padding: 15px 30px;
        border-radius: 4px;
        position: absolute;
        z-index: v-bind(zIndex);
        background: #fff;
        top:calc(50% - 60px);
        left: calc(50% - 100px);
        div{
            @include flex-style(row,nowrap,center,center);
            >span{
                font-size: 14px;
                margin-left: 4px;
            }
            &:nth-child(1){
                margin-bottom: 20px;
            }
        }
    }
</style>
