export const tableConfig = [{
    prop: "amount",
    label: "金额",
    fixed: true
},
{
    prop: "note",
    label: "套餐描述",
    fixed: true
},
{
    prop: "type",
    label: "套餐类型",
    type:'state',
    dictType: 'recharge_type',
    fixed: true
},
{
    prop: "day",
    label: "套餐时长",
    fixed: true
},
{
    prop:"company",
    label:"时长单位",
    type:'state',
    dictType:'company_type'
},
{
    prop: "createTime",
    label: "创建时间",
}
]

export const formConfig=[
    {
        prop: "amount",
        label: "金额"
    },
    {
        prop: "note",
        label: "套餐描述"
    },
    {
        prop: "type",
        label: "套餐类型",
        type:'select',
        
        dictType: 'recharge_type',
    },
    {
        prop:"company",
        label:"时长单位",
        type:'select',
        required:false,
        dictType:'company_type'
    },
    {
        prop: "day",
        label: "套餐时长",
        required:false,
    },

]




