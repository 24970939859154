export const tableConfig=[
    {
        prop: "name",
        label: "标题",
    },
    {
        prop: "url",
        label: "图片",
        type:"img"
    },
    {
        prop: "sort",
        label: "排序",
    },
]

export const formConfig=[
    {
        prop: "name",
        label: "标题",
    },
    {
        prop: "url",
        label: "图片",
        type:"img",
        tips:'尺寸347 * 82'
    },
    {
        prop: "sort",
        label: "排序",
        type:"number"
    },
]