import {ref} from "vue"
import exportExcel from "@/common/exportExcel"
import _axios from "@/plugins/axios"
export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption,//抽屉组件参数定义
){
	//搜索
    searchOption.searchType = 'conditionQuery'
	searchOptionsConditionQuery.options = [
        {label:'用户名称',prop:'userName',type:'text'},
        {label:'电话号码',prop:'userPhone',type:'text'},
        {label:'订单号',prop:'payNo',type:'text'},
        {label:'订单金额',prop:'payNo',type:'numberBridge',start:'minValue',end:'maxValue'},
        // {label:'专区类型',prop:'regionId',type:'select',options:[],lkey:'regionName',vkey:'id'},
        {label:'支付状态',prop:'payStatus',type:'select',options:[
            {label: "已支付", value: 1},
            {label: "未支付", value: 0},
        ]},
        {label:'支付方式',prop:'payType',type:'select',dictType:"pay-type"},
        {label:'创建日期',prop:'createTime',type:'date'},
        {label:'时间段筛选',prop:'mdate',type:'mdate',start:'startTime',end:'endTime'},
       
    ]

    pageParams.pageSize=14
    tableRequestOption.url = '/sys/orderPayinfo/page'

    const exportParam =ref({
        pageNum: 1,
        pageSize: pageParams.pageSize,
        size: pageParams.pageSize,
        pageEnd: 1,
        columnNames:[]
    })

    //导出当前页
    handleEventObj.exportCurrentPage = (item)=>{
        // console.log(searchParams.value)
        // console.log(pageParams)
        let params = {
            ...searchParams.value,
            ...pageParams
        }
        exportExcel('/sys/orderPayinfo/execl',params)
    }
    //更多导出配置
    handleEventObj.moreExport = (item)=>{
        drawerOption.show = true
        drawerOption.title = '更多导出设置'
        drawerOption.size = '40%'
        drawerOption.direction = 'ttb'
        drawerOption.customCopName = 'customExcelExport'
        drawerOption.item = {
            item:item,
            searchParams:searchParams.value,
            pageParams:pageParams,
            url:'/sys/orderPayinfo/execl',
            fieldOption:[
                { name: '用户名称', prop: 'userName', checked: true },
                { name: '电话号码', prop: 'userPhone', checked: true },
                { name: '订单号', prop: 'payNo', checked: true },
                { name: '订单金额', prop: 'orderAmount', checked: true },
                { name: '支付状态', prop: 'payStatus', checked: true },		
                { name: '支付金额', prop: 'payAmount', checked: true },
                { name: '支付方式', prop: 'payType', checked: true },
                { name: '创建时间', prop: 'createTime', checked: true },
            ]
        }
    }

    //导出参数配置
    // tableOption.exportOpt={
    //     url:'',
    //     fieldOption:[
    //         { name: '用户名称', prop: 'userName', checked: true },
    //         { name: '电话号码', prop: 'userPhone', checked: true },
    //         { name: '订单号', prop: 'payNo', checked: true },
    //         { name: '专区类型', prop: 'regionName', checked: true },
    //         { name: '订单金额', prop: 'orderAmount', checked: true },
    //         { name: '支付状态', prop: 'payStatus', checked: true },		
    //         { name: '支付金额', prop: 'payAmount', checked: true },
    //         { name: '支付方式', prop: 'payType', checked: true },
    //         { name: '创建时间', prop: 'createTime', checked: true },
    //     ]
    // },
    // 获取专区类型
    // getSpecialArea()
    // async function getSpecialArea(){
    //     const res = await _axios('post','/sys/productRegion/list',{},true)
    //     searchOptionsConditionQuery.options[3].options = res
    // }
}
