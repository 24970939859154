export const tableConfig = [{
    prop: "name",
    label: "品种名称"
},
{
    prop: "createTime",
    label: "创建时间",
}
]

export const formConfig=[
    {
        prop: "name",
        label: "品种名称"
    }
]

