import _axios from "@/plugins/axios";
import showToast from "@/common/showToast";
import exportExcel from "@/common/exportExcel";

export default function (
    store, route, router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
) {
    searchOption.searchType = 'customConditionQuery'
    searchOptionsConditionQuery.options = [
        { label: '品种名称', prop: 'varieties_like', type: 'text' },
        { label: '备注', prop: 'note_like', type: 'text' },
        { label: '地址', prop: 'address_like', type: 'text' },
    ]



    pageParams.pageSize = 14
    tableRequestOption.url = '/sys/supplyDemand/page'
    tableRequestOption.params  ={searchMap:{type_equal:1}}
    //删除参数
    tableDelDataOption.url = '/sys/supplyDemand/delete'
    tableDelDataOption.type = 'bodyArr'
    // tableOption.exportOpt.fieldOption = [
    //     { name: '套餐名称', prop: 'name', checked: true },
    //     { name: '套餐描述', prop: 'note', checked: true },
    //     { name: '实付价格', prop: 'amount',checked: true },
    //     { name: '赠送苗币数量', prop: 'giveAmount', checked: true,},
    //     { name: '创建时间', prop: 'createTime', checked: true },
    // ]
}