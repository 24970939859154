import showToast from "@/common/showToast"
import _axios from "@/plugins/axios"

export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
){
    //搜索
	searchOptionsFuzzyQuery.val = {
	    keys:'dictName',
	    placeholder:'字典名称'
	}

    pageParams.pageSize=15

    formDialogOption.defaultItem = {status:1}
    
    tableRequestOption.url = '/sys/sysDictType/page'

    //删除参数
    tableDelDataOption.url = '/sys/sysDictType/delete'
    tableDelDataOption.type = 'bodyArr'
    tableDelDataOption.valkey = 'dictId'

    //字典值
    handleEventObj.dictVal = (item)=>{
        const {dictName,dictType} = item
        store.commit('page/setSubRoutParam',{path:route.path,page:{...pageParams},search:{...searchParams.value}})
        router.push({path:'/system/dictionaryVal',query:{content:dictName,title:'系统字典',dictType:dictType,icon:'back'}})
    }
    //删除字典后刷新本地缓存
    handleEventObj.delSuccess = ()=>{
        store.dispatch('page/getDictVal')
    }
    //刷新服务端字典缓存
    handleEventObj.refreshDict = async ()=>{
        await _axios('get',`/sys/sysDict/refresh`,{},true)
        showToast.success("redis缓存成功")
    }
    //手动刷新本地字典值缓存
    handleEventObj.refreshLocalDict = ()=>{
        store.dispatch('page/getDictVal')
    }
}