export const tableConfig = [
    {
        prop: "name",
        label: "微信昵称"
    },
    {
        prop: "phone",
        label: "手机号",
    },{
        prop:'balance',
        label:'苗币余额'
    }, {
        prop: "planStore",
        label: "店铺名称"
    },
    {
        prop: "address",
        label: "店铺地址"
    },
    {
        prop: "memberType",
        label: "会员类型",
        type: 'state',
        dictType: 'member_type'
    },
    {
        prop: "memberDay",
        label: "会员到期时间",
    },
    {
        prop: "createTime",
        label: "注册时间"
    }, {
        prop: "priceType",
        label: "是否是苗木处理会员",
        type: 'state',
        dictType: 'price_type'
    }, {
        prop: "priceDay",
        label: "苗木处理会员到期时间"
    }, {
        prop: "topStatus",
        label: "苗店是否置顶",
        type: 'state',
        dictType: 'top_status'
    }, {
        prop: "topTime",
        label: "苗店置顶到期时间"
    }
]
export const formConfig = [
    {
        prop: "name",
        label: "微信昵称", 
        required: false,

    },
    {
        prop: "phone",
        label: "手机号", 
        required: false,
    },{
        prop:'balance',
        label:'苗币余额',
        required: false,
    }, {
        prop: "planStore",
        label: "店铺名称", 
        required: false,
    },
    {
        prop: "address",
        label: "店铺地址",
         required: false,
    },
    {
        prop: "memberType",
        label: "会员类型",
        type: 'select',
        dictType: 'member_type',
        required: false,
    },
    {
        prop: "memberDay",
        label: "会员到期时间",
        type:'datetime',
     
        required: false,

    },
 {
        prop: "priceType",
        label: "是否是苗木处理会员",
        type: 'select',
        dictType: 'price_type',
        required: false,
    }, {
        prop: "priceDay",
        label: "苗木处理会员到期时间",
        type:'datetime',
        required: false,
    }, {
        prop: "topStatus",
        label: "苗店是否置顶",
        type: 'select',
        dictType: 'top_status',
        required: false,
    }, {
        prop: "topTime",
        label: "苗店置顶到期时间",
        required: false,
    }
]