export const tableConfig=[
    {
        prop: "content",
        label: "反馈内容",
    },
    {
        prop: "url",
        label: "反馈图片",
        type:"mimg"
    },
    {
        prop: "createTime",
        label: "创建时间"
    }
]

