<template>
    <div class="header-title">
        <el-page-header :title="title" :icon='icon' @back="backEvet">
            <template #content>
                <h4 class="nav-list">
                    <span class="nl-sub">{{ content }}</span>
                    <template v-if="subtitle">
                        <el-icon style="margin:0 10px"><DArrowRight /></el-icon>
                        <span class="nl-third">{{ subtitle }}</span>
                    </template>
                </h4>
            </template>
        </el-page-header>
        <el-button type="primary" v-if="hasRef" icon="Refresh" plain @click="refresh">刷新</el-button>
    </div>
</template>
<script>
    export default {
        name:"HeaderTitle"
    }
</script>
<script setup>
    import { useRouter } from "vue-router";

    const router = useRouter()

    const props = defineProps({
        title:String,
        content:String,
        subtitle:String,
        icon:{
            type:String,
            default:''
        },
        customBack:Boolean,
        hasRef:{
            type:[String,Boolean],
            default:true
        }
    })
    const emits = defineEmits(['back','refresh'])

    const backEvet = ()=>{
        if(!props.icon) return
        if(props.customBack){
            emits('back')
        }else{
            router.back()
        }
    }
    const refresh = ()=>{
        emits('refresh')
    }
</script>
<style lang="scss" scoped>
    .header-title{
        border: 1px solid $borderColor;
        padding:8px 6px;
        background: #fff;
        margin-bottom: 12px;
        @include flex-style(row,nowrap,space-between,center);
        :deep(.el-page-header__title){
            font-size: 16px;
            color:#666;
            font-weight: bold;
            cursor: auto;
        }
        :deep(.el-page-header__content){
            font-size: 14px;
            font-weight: normal;
            color:#999;
        }
    }
    .nav-list{
        @include flex-style(row,nowrap,flex-start,center);
        .nl-sub{
            font-size: 15px;
            color: #777;
        }
    }
</style>
