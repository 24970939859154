export default function (
    store, route, router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
) {
    searchOption.searchType = 'customConditionQuery'
    searchOptionsConditionQuery.options = [
        { label: '用户名称', prop: 'username_like', type: 'text' },
        { label: '支付状态', prop: 'payStatus_equal', type: 'select', dictType: "pay_status" },
        { label: '消费类型', prop: 'type_equal', type: 'select', dictType: "pay_type" },
       
    ]



    pageParams.pageSize = 20
    tableRequestOption.url = '/sys/payInfo/page'

    tableOption.exportOpt.fieldOption = [
        { name: '用户名称', prop: 'username', checked: true },
        { name: '支付金额', prop: 'amout', checked: true },
        { name: '支付状态', prop: 'payStatus', dictType: 'pay_status', checked: true },
        { name: '消费类型', prop: 'type', checked: true, dictType: 'pay_type' },
        { name: '创建时间', prop: 'createTime', checked: true },
   
    ]
}