export const tableConfig=[
    {
        prop:"title",
        label:'标题',
    },
    {
        prop:'brief',
        label:'简介',
    },
    {
        prop:'createTime',
        label:"创建时间",
    }
    // {
    //     prop:'sort',
    //     label:'排序',
    // }
]

export const formConfig=[
    {
        prop:"title",
        label:'标题',
    },
    {
        prop:'brief',
        label:'简介',
        type:'textarea'
    },
    {
        prop:'note',
        label:'简介',
        type:'editor'
    },
    // {
    //     prop:'sort',
    //     label:'排序',
    //     type:"number"
    // }
]