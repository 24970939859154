export const tableConfig = [{
    prop: "amount",
    label: "金额",
    fixed: true
},
{
    prop: "note",
    label: "套餐描述",
    fixed: true
},
{
    prop: "type",
    label: "套餐类型",
    type: 'state',
    dictType: 'recharge_type'
},
{
    prop: "套餐时长",
    label: "day",
},
{
    prop: "时长单位",
    label: "company",
    type: 'state',
    dictType: 'company_type'
},
{
    prop: "createTime",
    label: "创建时间",
}
]

export default function (
    store, route, router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
) {
  
    searchOption.searchType = 'customConditionQuery'
    searchOptionsConditionQuery.options = [
        { label: '套餐类型', prop: 'type_equal', type: 'select' ,dictType: 'recharge_type'}
    ]



    pageParams.pageSize = 50
    tableRequestOption.url = '/sys/recharge/page'
    //删除参数
    tableDelDataOption.url = '/sys/recharge/delete'
    tableDelDataOption.type = 'bodyArr'

    tableOption.exportOpt.fieldOption = [
        { name: '金额', prop: 'amount', checked: true },
        { name: '套餐描述', prop: 'note', checked: true },
        { name: '套餐类型', prop: 'type', checked: true, dictType: 'recharge_type' },
        { name: '套餐时长', prop: 'day', checked: false },
        { name: '时长单位', prop: 'company', checked: false, dictType: 'company_type' },
        { name: '创建时间', prop: 'createTime', checked: true, },

    ]
}