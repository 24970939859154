export default function(
    store,route,router,
    formColumnConfig,//表单label相关配置
    formOption,//表单配置
    clickBtnSelectObj,//自定义点击类型按钮配置
    fromHndleEventObj,
    _axios,
    showToast,
    showConfirm
){
    formOption.labelWidth = 120
    formOption.newApiOption.api = `/sys/angelSetting/addOrModify`


    getUserLevelList()
    async function getUserLevelList(){
        const res = await _axios('post','/sys/userLevel/list',{type:2},true)
        formColumnConfig[1].option = res
        formColumnConfig[1].lkey = 'levelName'
        formColumnConfig[1].vkey = 'levelId'
    }

    getAreaList()
    async function getAreaList(){
        const res = await _axios('post','/sys/productRegion/list',{},true)
        formColumnConfig[2].option = res
        formColumnConfig[2].lkey = 'regionName'
        formColumnConfig[2].vkey = 'id'
    }
}
