export const tableConfig = [
    {
        prop: "createTime",
        label: "发布时间"
    },
    {
        prop: "note",
        label: "评论内容"
    }
  
]