export const tableConfig=[
    {
        prop: "dictName",
        label: "字典名称",
    },
    {
        prop: "dictType",
        label: "字典类型标识",
    },
    {
        prop: "status",
        label: "状态",
        type:"state",
        option:[
            {label:'启用',value:1},
            {label:'禁用',value:0},
        ]
    },
    {
        prop:'remark',
        label:"备注",
        width:400
    },
    {
        prop: "createTime",
        label: "创建时间",
    }
]

export const formConfig=[
    {
        prop: "dictName",
        label: "字典名称",
    },
    {
        prop: "dictType",
        label: "字典类型标识",
    },
    {
        prop: "status",
        label: "状态",
        type:"select",
        option:[
            {label:'启用',value:1},
            {label:'禁用',value:0},
        ]
    },
    {
        prop:'remark',
        label:"备注",
        required:false
    },
]