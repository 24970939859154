 // const searchType = ['等于','不等于','包含（模糊）','不包含','大于','小于','空值','非空']
 export const searchType = ['equal','notequal','like','notlike','gt','lt','null','notnull','sort']
 export const searchSuffix = ['_equal','_notequal','_like','_notlike','_gt','_lt','_null','_notnull','_sort']
 export const searchTypeOption = [
    {title:'等于',suffix:'_equal'},
    {title:'不等于',suffix:'_notequal'},
    {title:'包含',suffix:'_like'},
    {title:'不包含',suffix:'_notlike'},
    {title:'大于',suffix:'_gt'},
    {title:'小于',suffix:'_lt'},
    {title:'空值',suffix:'_null'},
    {title:'非空值',suffix:'_notnull'},
 ]