export default function(
    store,route,router,
    formColumnConfig,//表单label相关配置
    formOption,//表单配置
    clickBtnSelectObj,//自定义点击类型按钮配置
    fromHndleEventObj,
    _axios,
    showToast,
    showConfirm
){
    formOption.labelWidth = 100
    formOption.newApiOption.api = `/sys/label/submit`
    // formOption.updateApiOption.api = `/sys/yunke/mgr/updateAdminInfo`

    
    getCategory()
    async function getCategory(){
        const res =await _axios('get','/sys/category/listCategory',{},true)
        formColumnConfig.map(v=>{
            if(v.prop==='categoryId'){
                v.lkey = 'categoryName'
                v.vkey = 'categoryId'
                v.option = res
            }
        })
    }
}
