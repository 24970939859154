export default function(
    store,route,router,
    formColumnConfig,//表单label相关配置
    formOption,//表单配置
    clickBtnSelectObj,//自定义点击类型按钮配置
    fromHndleEventObj,
    _axios,
    showToast,
    showConfirm
){
    formOption.labelWidth = 200
    formOption.newApiOption.api = `/sys/giftPacksPoolSetting/addOrModify`

    getAreaList()
    async function getAreaList(){
        const res = await _axios('post','/sys/productRegion/list',{},true)
        formColumnConfig[2].option = res
        formColumnConfig[2].lkey = 'regionName'
        formColumnConfig[2].vkey = 'id'
    }
}
