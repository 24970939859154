export const tableConfig=[
    {
        prop: "dictLabel",
        label: "字典标签",
        fixed:true
    },
    {
        prop: "dictValue",
        label: "字典键值",
        fixed:true
    },
    {
        prop: "dictSort",
        label: "字典排序",
    },
    {
        prop: "isDefault",
        label: "是否默认",
        type:"state",
        option:[
            {label:'是',value:1,style:'color:#3f48cc;font-weight:bold'},
            {label:'否',value:0,style:'color:#a349a4;font-weight:bold'},
        ]
    },
    {
        prop: "status",
        label: "状态",
        type:"state",
        option:[
            {label:'启用',value:1,style:'color:#bfb500;font-weight:bold'},
            {label:'禁用',value:0,style:'color:#ed1c24;font-weight:bold'},
        ]
    },
    {
        prop: "cssClass",
        label: "样式属性",
    },
    {
        prop: "listClass",
        label: "表格回显样式",
    },
    {
        prop:'remark',
        label:"备注",
    },
    {
        prop: "createTime",
        label: "创建时间",
    }
]

export const formConfig=[
    {
        prop: "dictLabel",
        label: "字典标签",
    },
    {
        prop: "dictValue",
        label: "字典键值",
        type:'number'
    },
    {
        prop: "dictSort",
        label: "字典排序",
        type:'number'
    },
    {
        prop: "isDefault",
        label: "是否默认",
        type:"select",
        option:[
            {label:'是',value:1},
            {label:'否',value:0},
        ]
    },
    {
        prop: "status",
        label: "状态",
        type:"select",
        option:[
            {label:'启用',value:1},
            {label:'禁用',value:0},
        ]
    },
    {
        prop: "cssClass",
        label: "样式属性",
        type:'textarea',
        required:false
    },
    {
        prop: "listClass",
        label: "表格回显样式",
        type:'textarea',
        required:false
    },
    {
        prop:'remark',
        label:"备注",
        required:false
    },
]