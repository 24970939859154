export default function (
    store, route, router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    drawerOption
) {
    searchOption.searchType = 'customConditionQuery'
    searchOptionsConditionQuery.options = [
        { label: '用户昵称', prop: 'name_like', type: 'text', },
        { label: '手机号', prop: 'phone_like', type: 'text', },
        { label: '店铺名称', prop: 'planStore_like', type: 'text', },
        { label: '店铺地址', prop: 'address_like', type: 'text', },
        { label: '会员类型', prop: 'memberType_equal', type: 'select',dictType:'member_type' },
    ]

    pageParams.pageSize = 20
    tableRequestOption.url = '/sys/user/page'
    //删除参数
    tableDelDataOption.url = '/sys/user/delete'
    tableDelDataOption.type = 'bodyArr'

    tableOption.exportOpt.fieldOption = [
        {
            prop: "name",
            name: "微信昵称", 
            checked: true,
    
        },
        {
            prop: "phone",
            name: "手机号", 
            checked: true,
        }, {
            prop: "planStore",
            name: "店铺名称", 
            checked: true,
        },
        {
            prop: "address",
            name: "店铺地址",
             checked: true,
        },
        {
            prop: "memberType",
            name: "会员类型",
            checked: true,
        },
        {
            prop: "memberTime",
            name: "会员到期时间",
            checked: true,
    
        },
        {
            prop: "createTime",
            name: "注册时间",
            checked: true,
        }, {
            prop: "priceType",
            name: "是否是苗木处理会员",
            checked: true,
        }, {
            prop: "priceDay",
            name: "苗木处理会员到期时间",
            checked: true,
        }, {
            prop: "topStatus",
            name: "苗店是否置顶",
            checked: true,
        }, {
            prop: "topTime",
            name: "苗店置顶到期时间",
            checked: true,
        }, {
            prop: 'praise',
            name: '好评数'
        }
    ]
}