<template>
    <div class="search-box">
        <template v-if="hasSearch">
            <h3>{{ searchOptions.placeholder }}</h3>
            <el-select v-model="searchValField" style="width:90px;border-radius:0">
                <el-option v-for="(item,index) in searchTypeOption" :key="index" 
                    :label="item.title"
                    :value="item.suffix">
                </el-option>
            </el-select>
            <el-input class="input-style" v-model="inputVal" :placeholder="'请输入'+searchOptions.placeholder+'查询'"></el-input>
            <el-button type="primary" class="search" :icon="Search" @click="search">搜索</el-button>
            <el-button class="reset" @click="reset">重置</el-button>
        </template>
        <!-- <el-input v-if="hasSearch" v-model="inputVal" :placeholder="'请输入'+searchOptions.placeholder+'查询'" class="single-search" clearable @keyup.enter="search" @clear="reset">
            <template #prepend>
                <span class="reset" @click="reset">重置</span>
            </template>
            <template #append>
                <el-button type="primary" :icon="Search" @click="search"></el-button>
            </template>
        </el-input> -->
        <slot></slot>
    </div>
</template>
<script>
    export default {
        name: "SearchBar"
    }
</script>
<script setup>
    import {Search} from '@element-plus/icons-vue'
    import { reactive, ref,watch } from "vue"
    // import { ElMessage } from 'element-plus'
    import showToast from '../../common/showToast';
    import { searchType,searchTypeOption } from './searchOption';

    const props = defineProps({
        searchOptions: {
            type: Object,
            default: () => {
               return {
                  placeholder:'关键字',
                  keys:'' 
               }
            }
        },
        hasSearch:{
            type:Boolean,
            default:true
        },
        noneSet:{
            type:Boolean,
            default:true
        },
        isCustom:{
            type:Boolean,
            default:false
        }
    })
    const emits = defineEmits(['reset','search'])

    const inputVal = ref('')
    const searchValField = ref('_like')
   
   

    //重置
    const reset = ()=>{
        inputVal.value = ""
        emits("reset")
    }
    //搜索
    const search = ()=>{
		if(!inputVal.value && props.noneSet){//没有输入值的时候直接调用重置
			return emits("reset")
		}
        if(!props.searchOptions.keys) return showToast.warning("请配置查询参数")
        const object = new Object();
        const searchKeys = props.searchOptions.keys+''+searchValField.value
        console.log(searchKeys)
        object[searchKeys] = inputVal.value.trim()
        const searchObj = {
            searchMap:object
        }
        emits("search",props.noneSet?searchObj:{})
    }
</script>

<style lang="scss" scoped>
    .search-box {
        border: 1px solid $borderColor;
        background: #fff;
        padding:6px;
        margin-top: -5px;
        margin-bottom: 8px;
        @include flex-style(row,nowrap,flex-start,center);
        h3{
            background: #ecf5ff;
            height: 32px;
            line-height: 32px;
            border: 1px solid #409eff;
            color: #409eff;
            padding: 0 12px;
            border-radius: 4px 0 0 4px;
            font-size: 14px;
        }
        .input-style{
            max-width: 260px;
            min-width: 200px;
           
        }
        :deep(.el-input__wrapper){
            border-radius: 0;
            border-right: 0;
        }
        .search{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
</style>
