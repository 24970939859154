export const tableConfig=[
    {
        prop: "name",
        label: "标题",
    },
    {
        prop: "showType",
        label: "展示类型",
        type:'state',
        option:[
            {label:'图片',value:1},
            // {label:'视频',value:2}
        ]
    },
    {
        prop: "url",
        label: "图片",
        type:"img"
    },
    // {
    //     prop: "videoUrl",
    //     label: "视频",
    //     type:"video"
    // },
    {
        prop: "sort",
        label: "排序",
    },
]

export const formConfig=[
    {
        prop: "name",
        label: "标题",
    },
    {
        prop: "showType",
        label: "展示类型",
        type:'select',
        option:[
            {label:'图片',value:1},
            // {label:'视频',value:2}
        ]
    },
    {
        prop: "url",
        label: "图片",
        type:"img",
        required:false,
        tips:'建议尺寸：351 * 146'
    },
    // {
    //     prop: "videoUrl",
    //     label: "视频",
    //     type:"video",
    //     required:false,
    // },
    {
        prop: "sort",
        label: "排序",
        type:"number"
    },
]